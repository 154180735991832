// @ts-nocheck

angular.module('yaltyApp')
    
    .controller('PartnerPushNotificationsController', function($scope, $http, API_PREFIX, GoogleAnalyticsService, SubscriptionNames, $rootScope, $cookies, $state, $mdDialog, $mdToast) {
        function setListHeight(){
            setTimeout(function () {
                if($scope.userMessagesData != undefined){
                    if($scope.userMessagesData.length == 0){
                        let tablazatFejlecHeight = document.getElementById('tablazatFejlec').offsetHeight;
                        $scope.listHeight = {'height': tablazatFejlecHeight + 'px'};
                    } else {
                        let table = document.getElementById("messageTable");
                        $scope.listHeight = { 'height': (table.offsetHeight + 18) + 'px'};
                    }
                } else {
                    let tablazatFejlecHeight = document.getElementById('tablazatFejlec').offsetHeight;
                    $scope.listHeight = {'height': tablazatFejlecHeight + 'px'};
                }
            }, 100);
        }
        $scope.userMessagesData = [];
        $scope.partnerSubscriptionType = $rootScope.partnerSubscriptionType;
        $scope.currentSubType = SubscriptionNames[$rootScope.partnerSubscriptionType];
        $scope.reqSubType = SubscriptionNames['premium'];
        $scope.messageTypes = [
            {
                id: 1,
                name: 'Egy adott ügyfél'
            },
            {
                id: 2,
                name: 'Minden ügyfél'
            }
        ];
        $scope.canSendIndividualPushMessages = $cookies.get('canSendIndividualPushMessages')

        const $inp = $(".ap-otp-input");

        $inp.on({
          paste(ev) { // Handle Pasting
            let clip = ev.originalEvent.clipboardData.getData('text').trim();
            clip = clip.replace(/\s+/g, '');
            clip = clip.replace('-', '');
            if (clip.length != 6) {
                ev.preventDefault();
                let pasteToast = $mdToast.simple().parent(document.getElementById('redeemPage'))
                .textContent(`Sikertelen beillesztés. A publikus azonosító formátuma helytelen.`)
                .position('bottom center')
                .hideDelay(2000)
                $mdToast.show(pasteToast).then(function () { }).catch(function () { });
            } else {
                // Split string to Array or characters
                const s = [...clip];
                // Populate inputs. Focus last input.
                $inp.val(i => s[i]).eq(5).focus(); 
            }
          },
          input(ev) { // Handle typing
            
            const i = $inp.index(this);
            if (this.value) $inp.eq(i + 1).focus();
          },
          keydown(ev) { // Handle Deleting
            
            const i = $inp.index(this);
            if (!this.value && ev.key === "Backspace" && i) $inp.eq(i - 1).focus();
          }
          
        });
        
        setListHeight();
        window.onresize = function(event) {
           setListHeight();
        };

        GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/hűségkártyák_üzenetküldés",
        "HU Partner [PRIVÁT] - Hűségkártya üzenetküldés oldal")

        $scope.loyaltyCardId = [];

        let filter = {getFilteredLCsByMarketingPackage: false};
        $http.post(API_PREFIX.url + '/loyaltyCard/getAllLoyaltyCardStampListViewData', filter).then(function (result) {
            if(document.getElementById('loadingOverlay')){
                document.getElementById('loadingOverlay').style.display = "none";
                document.getElementById('innerPartnerPageContainer').style.overflow = "unset";
            }
            if (result.data.errorCode == 0) {
                $http.get(API_PREFIX.url + '/loyaltyCard/getAllLoyaltyCardsListViewData').then(function (result2) {
                    if(document.getElementById('loadingOverlay')){
                        document.getElementById('loadingOverlay').style.display = "none";
                        document.getElementById('innerPartnerPageContainer').style.overflow = "unset";
                    }
                    if (result2.data.errorCode == 0) {
                        let filteredResult = result.data.allLoyaltyCardsListViewData.filter(list => list.loyaltyCardPromoStatus == 1 ||  list.loyaltyCardPromoStatus == 2);
                        let filteredResult2 = result2.data.allLoyaltyCardsListViewData.filter(list => list.loyaltyCardPromoStatus == 1 || list.loyaltyCardPromoStatus == 2);
                        $scope.allLoyaltyCardId = [];
                        for(let i = 0; i < filteredResult.length; i++){
                            $scope.allLoyaltyCardId.push({id: filteredResult[i].id, promotionName: filteredResult[i].promotionName + (filteredResult[i].loyaltyCardPromoStatus == 2 ? ' (kifuttatott)' : ''), type: 'loyaltyCardYalty', listId: $scope.allLoyaltyCardId.length});
                        }
                        for(let i = 0; i < filteredResult2.length; i++){
                            $scope.allLoyaltyCardId.push({id: filteredResult2[i].id, promotionName: filteredResult2[i].promotionName + (filteredResult2[i].loyaltyCardPromoStatus == 2 ? ' (kifuttatott)' : ''), type: 'loyaltyCard', listId: $scope.allLoyaltyCardId.length});
                        }
                        if($scope.allLoyaltyCardId.length == 1){
                            $scope.setAllOptions(true, $scope.allLoyaltyCardId, 'loyaltyCardId')
                        }
                        GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/hűségkártyák_üzenetküldés/hűségkártyalista_lekérdezése_sikeres",
                            "HU Partner [PRIVÁT] - Hűségkártya üzenetküldés oldal - Hűségkártya lista lekérdezése sikeres")
                    } else {
                        GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/hűségkártyák_üzenetküldés/hűségkártyalista_lekérdezése_sikertelen",
                            "HU Partner [PRIVÁT] - Hűségkártya üzenetküldés oldal - Hűségkártya lista lekérdezése sikertelen")
                    }
                });        
            } else {
                GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/hűségkártyák_üzenetküldés/hűségkártyalista_lekérdezése_sikertelen",
                    "HU Partner [PRIVÁT] - Hűségkártya üzenetküldés oldal - Hűségkártya lista lekérdezése sikertelen")
            }
        });

        $scope.navigateToSettings = function () {
            if (($scope.messageSubject == null || $scope.messageSubject == '') && ($scope.messageText == null || $scope.messageText == '')) {
                $state.go('home.settings');
            } else {
                var confirm = $mdDialog.confirm()
                    .title('Biztosan el szeretné hagyni az oldalt?')
                    .textContent('Az adatlapon történt módosításai mentés nélkül elvesznek.')
                    .ariaLabel('Oldal elhagyás megerősítése')
                    .ok('Igen')
                    .cancel('Mégsem');
                $mdDialog.show(confirm).then(function () {
                    $state.go('home.settings');
                }, function () {
                });
            }
        }

        $scope.sendMessage = function () {
            if(($scope.canSendIndividualPushMessages == 'true' && $scope.messageType == 1) && (publicUserId1.value.length != 1 || publicUserId2.value.length != 1 || publicUserId3.value.length != 1 || publicUserId4.value.length != 1 || publicUserId5.value.length != 1 || publicUserId6.value.length != 1)) {
                var confirm = $mdDialog.confirm()
                    .title('A Felhasználó publikus azonosítójának megadása kötelező!')
                    .ok('Rendben')
                $mdDialog.show(confirm).then(function () {
                    setTimeout(function () {
                        document.getElementById('publicUserId1').focus();
                    }, 200)
                }, function () {
                })
            } else if(($scope.canSendIndividualPushMessages != 'true' || $scope.messageType == 2) && $scope.loyaltyCardId.length == 0){
                $scope.messagingForm.loyaltyCardId.$setTouched()
                var confirm = $mdDialog.confirm()
                    .title('Az üzenet kiküldéséhez válasszon ki egy vagy több hűségkártya promóciót.')
                    .ok('Rendben')
                $mdDialog.show(confirm).then(function () {
                }, function () {
                })
            } else if(($scope.messageSubject == null || $scope.messageSubject.length == 0) && ($scope.messageText == null || $scope.messageText.length == 0)){
                var confirm = $mdDialog.confirm()
                    .title('Az üzenet címének vagy szövegének megadása kötelező.')
                    .ok('Rendben')
                $mdDialog.show(confirm).then(function () {
                }, function () {
                })
            } else if($cookies.get("adminUserType") != null){
                var confirm = $mdDialog.confirm()
                    .title('Csak partner küldhet üzenetet, YAdmin felhasználó nem.')
                    .ok('Rendben')
                $mdDialog.show(confirm).then(function () {
                }, function () {
                })
            } else {
                let textContent = '';
                if($scope.loyaltyCardId.length > 0) {
                    textContent = 'A Küldés gomb megnyomása után azonnal elküldjük üzenetét a kiválasztott hűségkártya promóció(k) felhasználói mobiljaira.'
                } else {
                    textContent = 'A Küldés gomb megnyomása után azonnal elküldjük üzenetét a kiválasztott felhasználó mobiljaira.'
                }
                var confirm = $mdDialog.confirm()
                    .title('Biztosan elküldi az üzenetet?')
                    .textContent(textContent)
                    .ok('Küldés')
                    .cancel('Mégsem');
                $mdDialog.show(confirm).then(function () {
                    let message = {
                        senderName: $cookies.get('userCompany'),
                        messageSubject: $scope.messageSubject,
                        messageText: $scope.messageText
                    };
                    if($scope.messageType == 2 || $scope.canSendIndividualPushMessages != 'true') {
                        let selectedLoyaltyCards = [];
                        let selectedLoyaltyCardYaltys = [];
                        for(let i = 0; i < $scope.allLoyaltyCardId.length; i++){
                            if($scope.loyaltyCardId.includes($scope.allLoyaltyCardId[i].listId)){
                                let obj = {};
                                obj.id = $scope.allLoyaltyCardId[i].id;
                                obj.promotionName = $scope.allLoyaltyCardId[i].promotionName;
                                if($scope.allLoyaltyCardId[i].type == 'loyaltyCardYalty'){
                                    selectedLoyaltyCardYaltys.push(obj);
                                } else if($scope.allLoyaltyCardId[i].type == 'loyaltyCard'){
                                    selectedLoyaltyCards.push(obj);
                                }
                            }
                        }
                        message.selectedLoyaltyCards = selectedLoyaltyCards;
                        message.selectedLoyaltyCardYaltys = selectedLoyaltyCardYaltys;
                    } else {
                        message.publicRedeemUserId = publicUserId1.value + publicUserId2.value + publicUserId3.value + publicUserId4.value + publicUserId5.value + publicUserId6.value;
                    }
                    $scope.sendButtonDisabled = true;
                    return $http.post(API_PREFIX.url + '/partner/sendPushNotification', message).then(function (result) {
                        $scope.sendButtonDisabled = false;
                        if (result.data.errorCode == 0) {
                            var confirm = $mdDialog.confirm()
                                .title('Üzenetét kiküldtük!')
                                .ok('Rendben')
                            $mdDialog.show(confirm).then(function () {
                                $state.reload();
                            }, function () {
                                $state.reload();
                            })
                            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/hűségkártyák_üzenetküldés/üzenet_küldés_sikeres",
                                "HU Partner [PRIVÁT] - Hűségkártya üzenetküldés oldal - Üzenet küldés sikeres")
                        } else if(result.data.errorCode == 4) {
                            var confirm = $mdDialog.confirm()
                                .title('Üzenetét nem tudtuk kiküldeni. Elérte a csomagjában foglalt havi üzenetküldési korlátot.')
                                .ok('Rendben')
                            $mdDialog.show(confirm).then(function () {
                                $state.reload();
                            }, function () {
                                $state.reload();
                            })
                            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/hűségkártyák_üzenetküldés/üzenet_küldés_sikertelen",
                                "HU Partner [PRIVÁT] - Hűségkártya üzenetküldés oldal - Üzenet küldés sikertelen")
                        } else if(result.data.errorCode == 6) {
                            var confirm = $mdDialog.confirm()
                                .title('Hibás vagy nem létező felhasználói publikus azonosító.')
                                .textContent("Kérjük ellenőrizze az azonosító jegyeit, az esetleges elírásokat.")
                                .ok('Rendben')
                            $mdDialog.show(confirm).then(function () {    
                            }, function () {
                            })
                            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/hűségkártyák_üzenetküldés/üzenet_küldés_sikertelen",
                                "HU Partner [PRIVÁT] - Hűségkártya üzenetküldés oldal - Üzenet küldés sikertelen")
                        } else if(result.data.errorCode == 7) {
                            var confirm = $mdDialog.confirm()
                                .title('Az üzenet normál üzenetként kiküldve. Push üzenet a felhasználónak nem küldhető.')
                                .textContent("Az üzenetet a felhasználó a következő Yalty app indításkor látni fogja. Push üzenet a felhasználónak nem küldhető, mert nincs olyan eszköze, amin a push üzenet fogadása engedélyezett lenne.")
                                .ok('Rendben')
                            $mdDialog.show(confirm).then(function () {
                                $state.reload();
                            }, function () {
                                $state.reload();
                            })
                            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/hűségkártyák_üzenetküldés/üzenet_küldés_sikertelen",
                                "HU Partner [PRIVÁT] - Hűségkártya üzenetküldés oldal - Üzenet küldés sikertelen")
                        } else if(result.data.errorCode == 8) {
                            var confirm = $mdDialog.confirm()
                                .title("Törölt felhasználó")
                                .textContent("A felhasználó időközben törölte a fiókját, így számára már üzenetküldés sem kezdeményezhető.")
                                .ok('Rendben')
                            $mdDialog.show(confirm).then(function () {
                                $state.reload();
                            }, function () {
                                $state.reload();
                            })
                            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/hűségkártyák_üzenetküldés/üzenet_küldés_sikertelen",
                                "HU Partner [PRIVÁT] - Hűségkártya üzenetküldés oldal - Üzenet küldés sikertelen")
                        } else {
                            var confirm = $mdDialog.confirm()
                                .title('Szerver hiba. Kérjük próbálja meg később újra.')
                                .ok('Rendben')
                            $mdDialog.show(confirm).then(function () {
                                $state.reload();
                            }, function () {
                                $state.reload();
                            })
                            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/hűségkártyák_üzenetküldés/üzenet_küldés_sikertelen",
                                "HU Partner [PRIVÁT] - Hűségkártya üzenetküldés oldal - Üzenet küldés sikertelen")
                        }
                    });
                }, function () {
                    
                })
            }
        };

        $scope.selectMessageType = function () {
            if($scope.messageType == 1) {
                $scope.checkAllLoyaltyCardId = false;
                $scope.loyaltyCardId = [];
                setTimeout(function () {
                    document.getElementById('publicUserId1').focus();
                }, 500)
            } else if($scope.messageType == 2) {
                publicUserId1.value= '';
                publicUserId2.value= '';
                publicUserId3.value= '';
                publicUserId4.value= '';
                publicUserId5.value= '';
                publicUserId6.value= '';
            }
        }

        $scope.setAllOptions = function (checkValue, array, model) {
            if (checkValue == true) {
                if (model == "loyaltyCardId") {
                    $scope.loyaltyCardId = [];
                    for (let i = 0; i < $scope.allLoyaltyCardId.length; i++) {
                        $scope.loyaltyCardId.push($scope.allLoyaltyCardId[i].listId);
                    }
                }
            } else {
                if (model == "loyaltyCardId") {
                    $scope.loyaltyCardId = [];
                }
            }
        }

        $scope.getSelectedLoyaltyCardIds = function () {
            if ($scope.loyaltyCardId == null || $scope.loyaltyCardId.length == 0) {
                return "Hűségkártya promóció(k)"
            } else if ($scope.loyaltyCardId.length != $scope.allLoyaltyCardId.length || $scope.allLoyaltyCardId.length == 1) {
                let text = "";
                for(let i = 0; i < $scope.allLoyaltyCardId.length; i++){
                    if($scope.loyaltyCardId.includes($scope.allLoyaltyCardId[i].listId)){
                        text += $scope.allLoyaltyCardId[i].promotionName;
                        text += ", ";
                    }
                }
                text = text.substring(0, text.length-2);
                return text;
            } else {
                return "Összes";
            }
        };
        $scope.selectLoyaltyCardId = function () {
            if ($scope.loyaltyCardId == null || $scope.loyaltyCardId.length != $scope.allLoyaltyCardId.length) {
                $scope.checkAllLoyaltyCardId = false;
            } else {
                $scope.checkAllLoyaltyCardId = true;
            }
        };
        $http.post(API_PREFIX.url + '/partner/getListOfPushNotificationsByPartner', null).then(function (result) {
            if(document.getElementById('loadingOverlay')){
                document.getElementById('loadingOverlay').style.display = "none";
                document.getElementById('innerPartnerPageContainer').style.overflow = "unset";
            }
            if (result.data.errorCode == 0) {
                $scope.showUserMessagesData = true;
                $scope.nrOfRemainingPushNotifications = result.data.nrOfRemainingPushNotifications;
                $scope.nrOfRemainingIndividualPushNotifications = result.data.nrOfRemainingIndividualPushNotifications;
                $scope.periodStartTimestamp = result.data.periodStartTimestamp;
                $scope.periodEndTimestamp = result.data.periodEndTimestamp;
                $scope.compLogoPushNotificationImgURL = result.data.compLogoPushNotificationImgURL;
                if($scope.compLogoPushNotificationImgURL == null){
                    var confirm = $mdDialog.confirm()
                    .textContent('Üzenet küldéséhez be kell állítania a Push üzenet értesítés képet a PARTNERI ADATOK / Partneri adatok oldal ugyanezen nevű mezőjénél.')
                    .ok('Beállítás')
                    .cancel('Mégsem');
                    $mdDialog.show(confirm).then(function () {
                        $state.go("home.settings")
                    }, function () {
                        history.back();
                    });
                }
                $scope.limitMonthlyPushMessageMaxNo = $cookies.get('limitMonthlyPushMessageMaxNo');
                $scope.limitMonthlyIndividualPushMessageMaxNo = $cookies.get('limitMonthlyIndividualPushMessageMaxNo');
                for(let i = 0; i < result.data.userMessages.length; i++){
                    if(result.data.userMessages[i].etc != null){
                        result.data.userMessages[i].etc = result.data.userMessages[i].etc.substring(16, result.data.userMessages[i].etc.length);
                        result.data.userMessages[i].etc = result.data.userMessages[i].etc.replace(/,/g, ", ");
                    }
                }
                $scope.userMessagesData = result.data.userMessages;
                if(result.data.userMessages.length == 0){
                    $scope.showInfo = true;
                } else {
                    $scope.showInfo = false;
                }
                GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/hűségkártyák_üzenetküldés/üzenetlista_lekérdezése_sikeres",
                    "HU Partner [PRIVÁT] - Hűségkártya üzenetküldés oldal - Üzenet lista lekérdezése sikeres")
            } else {
                $scope.userMessagesData = [];
                $scope.showUserMessagesData = false;
                $scope.showInfo = true;
                GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/hűségkártyák_üzenetküldés/üzenetlista_lekérdezése_sikertelen",
                "HU Partner [PRIVÁT] - Hűségkártya üzenetküldés oldal - Üzenet lista lekérdezése sikertelen")
            }
            setListHeight();
        });
        
    })