import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { forkJoin, merge, Subject, Subscription } from 'rxjs';
import { GetAllCompanyStoreDataForLCYDashboardStoreSelectorResponse } from 'src/app/shared/interfaces/stores';
import { SelectInput } from 'src/app/shared/interfaces/input';
import { HttpService } from 'src/app/shared/services/http.service';
import { CookieService } from 'ngx-cookie-service';
import {
    GetAllLoyaltyCardsListViewDataRequest,
    GetAllLoyaltyCardsListViewDataResponse,
    GetLCYAnalyticsDasboardTimeSeriesDataRequest,
    GetLCYAnalyticsDasboardTimeSeriesDataResponse,
    GetLCYAnalyticsDashboardStatsDataRequest,
    GetLCYAnalyticsUserStatsDataRequest,
    LCYAnalyticsDasboardTimeSeriesStats,
    LCYAnalyticsDashboardStats,
    LCYAnalyticsUserStats
} from 'src/app/shared/interfaces/loyalty-cards';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePeriodService } from 'src/app/shared/services/date-period.service';
import { MultipleSelectService } from 'src/app/shared/services/multiple-select.service';
import { MultipleSelect } from 'src/app/shared/classes/multiple-select';
import { FormValidatorService } from 'src/app/shared/services/form-validator.service';
import { MatRipple } from '@angular/material/core';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { AggregationType } from 'src/app/types/aggregation-type';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { ChartService } from 'src/app/shared/services/chart.service';
import { debounceTime } from 'rxjs/operators';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
    selector: 'app-loyalty-card-yalty-time-series-stats',
    templateUrl: './loyalty-card-yalty-time-series-stats.component.html',
    styleUrls: ['./loyalty-card-yalty-time-series-stats.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LoyaltyCardYaltyTimeSeriesStatsComponent implements OnInit {

    currentState = 'KIMUTATÁSOK';
    currentSubState = 'Időszaki grafikonok';
    reqSubType = 'Premium';
    currentSubType: string | undefined;
    adminUserType = this.cookieService.get('adminUserType');

    filtersForm: FormGroup;
    timeSeriesForm: FormGroup;
    displayedStatsForm: FormGroup;
    //sectionSelectorField: FormControl;
    activeStoreFilterField: FormControl;

    activeFilterSet = false;

    loyaltyCardTemplates: SelectInput[] = [];
    stores: SelectInput[] = [];
    getAllLoyaltyCardsListViewDataRequest: GetAllLoyaltyCardsListViewDataRequest = {
        getFilteredLCsByMarketingPackage: false
    }
    getLCYAnalyticsDashboardStatsDataRequest: GetLCYAnalyticsDashboardStatsDataRequest;
    getLCYAnalyticsDasboardTimeSeriesDataRequest: GetLCYAnalyticsDasboardTimeSeriesDataRequest;
    getLCYAnalyticsUserStatsDataRequest: GetLCYAnalyticsUserStatsDataRequest;
    lCYDashboardStats: LCYAnalyticsDashboardStats;
    lCYDashboardTimeSeriesStats: LCYAnalyticsDasboardTimeSeriesStats[];
    lCYAnalyticsUserStats: LCYAnalyticsUserStats[];
    filteredUserStats: LCYAnalyticsUserStats[] = [];
    timeSeriesChartDatasets: ChartDataSets[] = [];
    timeSeriesChartLabels: Label[] = [];
    timeSeriesChartColors: Color[] = [];
    timeSeriesChartLegend = true;
    timeSeriesChartType = 'line';
    timeSeriesChartType2 = 'bar';
    chartType = 'line';
    timeSeriesChartDisplayable = true;
    timeSeriesChartPlugins = [];
    timeSeriesChartOptions = this.chartService.defaultChartOptions;
    allStores$ = this.httpService.get<GetAllCompanyStoreDataForLCYDashboardStoreSelectorResponse>('/company/getAllCompanyStoreDataForLCYDashboardStoreSelector');
    allLoyaltyCardTemplate$ = this.httpService.post<GetAllLoyaltyCardsListViewDataResponse>('/loyaltyCard/getAllLoyaltyCardStampListViewData', this.getAllLoyaltyCardsListViewDataRequest);
    allInitAPICall$ = forkJoin([this.allStores$, this.allLoyaltyCardTemplate$]);
    allStoresResult: GetAllCompanyStoreDataForLCYDashboardStoreSelectorResponse
    initApiCallFinished = false;
    showLCYDashboardStats = false;
    showLCYDashboardTimeSeriesStats = false;
    showLCYUserStats = false;
    totalNrOfActiveStores = 0;
    storeFilterDisplayValue: string | undefined;
    periodOptions = this.datePeriodService.periodOptions;
    multipleSelect = new MultipleSelect();
    resizeListContainer = new Subject<void>();
    storeListHeight: object;
    resizeObservable$ = merge(this.commonService.resizeObservable$, this.resizeListContainer.pipe(debounceTime(100)));
    savedLoyaltyCardFilterStates: SectionStates = {
        dashboard: [],
        time: [],
        other: [],
        customer: []
    }
    savedStoreFilterStates: SectionStates = {
        dashboard: [],
        time: [],
        other: [],
        customer: []
    }
    globalLoyaltyCardFilter: (string | number)[];
    globalStoreFilter: (string | number)[];
    aggregationTypeOptions: SelectInput[] = [
        { value: 1, display: 'Napi' },
        { value: 2, display: 'Heti' },
        { value: 3, display: 'Havi' }];

    /*sectionSelectorOptions: SelectInput[] = [
        { value: 'dashboard', display: 'Dashboard' },
        { value: 'time', display: 'Időszaki grafikonok' },
        { value: 'other', display: 'Egyéb grafikonok' },
        { value: 'customer', display: 'Ügyfél lista' }];*/

    /*sectionsLoaded: SectionNames = {
        dashboard: false,
        time: false,
        other: false,
        customer: false
    };*/
    /*sectionsLoading: SectionNames = {
        dashboard: false,
        time: false,
        other: false,
        customer: false
    };*/
    @ViewChild(MatRipple) ripple: MatRipple;
    waitingForAPIResult = false;

    private subscriptions: Subscription[] = [];

    defaultFiltersFormValues = {
        loyaltyCardFilter: null,
        storeFilter: null
    };
    defaultSectionSelectorValue = 'dashboard'
    defaultActiveStoreFilterValue = true;
    defaultTimeSeriesFormValues = {
        period: 7,
        periodStartTimestamp: this.datePeriodService.getDatesFromSelectedPeriod(7).fromTimestamp,
        periodEndTimestamp: this.datePeriodService.getDatesFromSelectedPeriod(7).endTimestamp,
        aggregationType: 1
    };
    defaultUserStatsFormValues = {
        period: 7,
        periodStartTimestamp: this.datePeriodService.getDatesFromSelectedPeriod(7).fromTimestamp,
        periodEndTimestamp: this.datePeriodService.getDatesFromSelectedPeriod(7).endTimestamp,
        aggregationType: 1
    };


    constructor(
        private httpService: HttpService,
        private dialogService: DialogService,
        private formBuilder: FormBuilder,
        private cookieService: CookieService,
        private datePeriodService: DatePeriodService,
        private multipleSelectService: MultipleSelectService,
        private formValidatorService: FormValidatorService,
        private chartService: ChartService,
        private commonService: CommonService,
    ) { }

    ngOnInit(): void {
        if(this.cookieService.get('sbcrtyp').includes('yalty_start_free')) {
            this.currentSubType = 'Yalty Start'
        } else if(this.cookieService.get('sbcrtyp').includes('yalty_start_paid')) {
            this.currentSubType = 'Yalty Start'
        } else if(this.cookieService.get('sbcrtyp').includes('basic')) {
            this.currentSubType = 'Design+'
        } else if(this.cookieService.get('sbcrtyp').includes('premium')) {
            this.currentSubType = 'Premium'
        } else if(this.cookieService.get('sbcrtyp').includes('custom')) {
            this.currentSubType = 'Egyedi'
        }
        this.filtersForm = this.formBuilder.group({
            loyaltyCardFilter: [this.defaultFiltersFormValues.loyaltyCardFilter, Validators.required],
            storeFilter: [this.defaultFiltersFormValues.storeFilter, Validators.required]
        });
        //this.sectionSelectorField = new FormControl(this.defaultSectionSelectorValue);
        this.activeStoreFilterField = new FormControl(this.defaultActiveStoreFilterValue);
        this.timeSeriesForm = this.formBuilder.group({
            period: [this.defaultTimeSeriesFormValues.period, Validators.required],
            periodStartTimestamp: [this.defaultTimeSeriesFormValues.periodStartTimestamp],
            periodEndTimestamp: [this.defaultTimeSeriesFormValues.periodEndTimestamp],
            aggregationType: [this.defaultTimeSeriesFormValues.aggregationType, Validators.required]
        });
        this.timeSeriesForm = this.formBuilder.group({
            period: [this.defaultTimeSeriesFormValues.period, Validators.required],
            periodStartTimestamp: [this.defaultTimeSeriesFormValues.periodStartTimestamp],
            periodEndTimestamp: [this.defaultTimeSeriesFormValues.periodEndTimestamp],
            aggregationType: [this.defaultTimeSeriesFormValues.aggregationType, Validators.required]
        });
        this.displayedStatsForm = this.formBuilder.group({
            displayedStats: [['totalNrOfUsers', 'nrOfNewUsers']],
        });
        this.subscriptions.push(
            this.allInitAPICall$.subscribe(results => {
                this.initApiCallFinished = true;
                this.allStoresResult = results[0];
                const loyaltyCards = results[1];
                this.updateStores(this.activeStoreFilter);
                this.storeFilterField.setValue(['all']);
                for (let i = 0; i < loyaltyCards.allLoyaltyCardsListViewData.length; i++) {
                    if (loyaltyCards.allLoyaltyCardsListViewData[i].loyaltyCardPromoStatus !== 0) {
                        const promoStatus = loyaltyCards.allLoyaltyCardsListViewData[i].loyaltyCardPromoStatus;
                        const promotionName = loyaltyCards.allLoyaltyCardsListViewData[i].promotionName
                        this.loyaltyCardTemplates.push({
                            value: loyaltyCards.allLoyaltyCardsListViewData[i].id,
                            display: this.getPromotionNameDisplayValue(promoStatus, promotionName)
                        });
                    }
                }
                this.loyaltyCardFilterField.setValue(['all']);
                setTimeout(() => {
                    // SetTimeout 0 needed because this.loyaltyCardFilterField.setValue(['all']) going to tick off all options
                    // but it takes time, because options not rendered yet and it's work async.
                    // In case of store it works, because it's a locally declared not a component from other files which using data bindings.   
                    //this.savedLoyaltyCardFilterStates[this.sectionSelector] = this.loyaltyCardFilter;
                    this.globalLoyaltyCardFilter = this.loyaltyCardFilter;
                    if(this.loyaltyCardTemplates.length > 0 && this.stores.length > 0 && (this.adminUserType=='' || this.adminUserType=='executive') && (this.currentSubType == 'Egyedi' || this.currentSubType == 'Premium')) {
                        this.update();
                    }
                }, 0);
                //this.sectionsLoaded[this.sectionSelector] = true;
                //this.sectionsLoading[this.sectionSelector] = true;
                //this.savedStoreFilterStates[this.sectionSelector] = this.storeFilter;
                this.globalStoreFilter = this.storeFilter;
            }),
            this.storeFilterField.valueChanges.subscribe(() => {
                this.updateStoreFilters();
            }),
            this.displayedStatsField.valueChanges.subscribe(() => {
                this.setTimeSeriesDataSets(this.displayedStats.includes('totalNrOfUsers'), this.displayedStats.includes('nrOfNewUsers'), this.displayedStats.includes('totalNrOfInstances'), 
                this.displayedStats.includes('nrOfNewInstances'), this.displayedStats.includes('totalNrOfStamps'), this.displayedStats.includes('totalNrOfRedeems'));
            }),
            this.timeSeriesPeriodField.valueChanges.subscribe(val => {
                const { fromTimestamp, endTimestamp } = this.datePeriodService.getDatesFromSelectedPeriod(val);
                this.timeSeriesPeriodStartTimestampField.setValue(fromTimestamp);
                this.timeSeriesPeriodEndTimestampField.setValue(endTimestamp);
            }),
            this.timeSeriesAggregationTypeField.valueChanges.subscribe(val => {
                this.timeSeriesPeriodField.setValue(this.timeSeriesPeriodField.value);
                const { fromTimestamp, endTimestamp } = this.datePeriodService.getDatesByAggregation(val, this.timeSeriesPeriodStartTimestamp, this.timeSeriesPeriodEndTimestamp);
                this.timeSeriesPeriodStartTimestampField.setValue(fromTimestamp);
                this.timeSeriesPeriodEndTimestampField.setValue(endTimestamp);
            }),
            this.activeStoreFilterField.valueChanges.subscribe(val => {
                this.updateStores(val);
                this.updateStoreFilters(true);
            }),
        );
    }

    getPromotionNameDisplayValue(promoStatus: number, promotionName: string): string {
        if (promoStatus == 1) return `${promotionName} (aktív)`
        else if (promoStatus == 2) return `${promotionName} (kifuttatott)`
        else if (promoStatus == 3) return `${promotionName} (deaktivált)`
        else return ''
    }

    updateStores(active: boolean) {
        const currentStores: SelectInput[] = [];
        for (let i = 0; i < this.allStoresResult.companyStores.length; i++) {
            let needToAdd = false;
            if (active) needToAdd = this.allStoresResult.companyStores[i].stampOrRedeemHappened;
            else needToAdd = true;
            if (needToAdd) currentStores.push({
                value: this.allStoresResult.companyStores[i].id,
                display: this.allStoresResult.companyStores[i].compStoreName
            });
        }
        this.stores = currentStores;
    }

    updateStoreFilters(activeFilterChanged?: boolean) {
        let all = false, deleted = false, virtual = false;
        const active = this.activeStoreFilter;
        for (let i = 0; i < this.storeFilter.length; i++) {
            if (all && deleted && virtual) break;
            if (this.storeFilter[i] === 'all') all = true;
            else if (this.storeFilter[i] === 'deleted') deleted = true;
            else if (this.storeFilter[i] === 'virtual' && !active) virtual = true;
        }
        const visibleStores = this.stores.map(store => store.value);
        if (all) visibleStores.unshift('all');
        let selectedStores;
        if (all && activeFilterChanged) {
            selectedStores = visibleStores;
        } else {
            const visibleSelectedStores = this.storeFilter.filter(store => visibleStores.includes(store));
            selectedStores = this.multipleSelect.getOptions(
                this.stores, visibleSelectedStores, all);
        }
        if (deleted) selectedStores.unshift('deleted');
        if (virtual) selectedStores.unshift('virtual');
        this.storeFilterField.setValue(selectedStores, { emitEvent: false });
        this.updateStoreDisplayValue(all, deleted, virtual);

    }

    updateStoreDisplayValue(all: boolean, deleted: boolean, virtual: boolean) {
        const stores = this.stores.filter(store => this.storeFilter.includes(store.value));
        const displayValues = stores.map(o => o.display);
        if (all) displayValues.push('all');
        if (virtual) displayValues.unshift('Online vagy telefonos rendelések (Nincs üzlet)');
        if (deleted) displayValues.unshift('Törölt üzletek');
        this.storeFilterDisplayValue = this.multipleSelectService.getDisplayValue(
            displayValues
        ) || 'Mind';
    }

    saveGlobalFilters() {
        this.globalLoyaltyCardFilter = this.loyaltyCardFilter;
        this.globalStoreFilter = this.storeFilter;
    }

    setNrOfActiveStores() {
        let n = this.stores.length + this.allStoresResult.deletedCompanyStoreIds.length;
        if (this.allStoresResult.wasStampOrRedeemWithoutStore && !this.activeStoreFilter) n++;
        this.totalNrOfActiveStores = n;
    }

    getDeletedOptionSelected(updateButtonPressed: boolean): boolean {
        if (updateButtonPressed) return this.storeFilter.includes('deleted');
        else return this.globalStoreFilter.includes('deleted');
    }

    getVirtualOptionSelected(updateButtonPressed: boolean): boolean {
        if (updateButtonPressed) return this.storeFilter.includes('virtual');
        else return this.globalStoreFilter.includes('virtual');
    }

    getStoresForRequestParams(deletedOptionSelected: boolean, virtualOptionSelected: boolean, updateButtonPressed: boolean): (string | number)[] {
        let stores = [];
        if (updateButtonPressed) stores = this.storeFilter.filter(store => store !== 'all' && store !== 'virtual' && store !== 'deleted');
        else  stores = this.globalStoreFilter.filter(store => store !== 'all' && store !== 'virtual' && store !== 'deleted');
        if (deletedOptionSelected) stores.push(...this.allStoresResult.deletedCompanyStoreIds);
        if (virtualOptionSelected) stores.push(0);
        return stores;
    }

    getLoyaltyCardsForRequestParams(updateButtonPressed: boolean): (string | number)[] {
        let loyaltyCards = [];
        if (updateButtonPressed) loyaltyCards = this.loyaltyCardFilter.filter(card => card !== 'all');
        else  loyaltyCards = this.globalLoyaltyCardFilter.filter(card => card !== 'all');
        return loyaltyCards;
    }

    update(updateButtonPressed: boolean = false) {
        if (!this.filtersForm.valid) {
            this.formValidatorService.validateAllFormFields(this.filtersForm);
        } else {
            this.getTimeSeriesData(updateButtonPressed);
        }
    }

    setTimeSeriesDataSets(showTotalNrOfUsers: boolean, showNrOfNewUsers: boolean, showTotalNrOfInstances: boolean, showNrOfNewInstances: boolean, showTotalNrOfStamps: boolean, showTotalNrOfRedeems: boolean) {
        this.timeSeriesChartDatasets = [
            {
                data: this.lCYDashboardTimeSeriesStats.map(stat => stat.totalNrOfUsers),
                label: 'Egyedi ügyfelek',
                hidden: !showTotalNrOfUsers
            },
            {
                data: this.lCYDashboardTimeSeriesStats.map(stat => stat.nrOfNewUsers),
                label: 'Új ügyfelek',
                hidden: !showNrOfNewUsers
            },
            {
                data: this.lCYDashboardTimeSeriesStats.map(stat => stat.totalNrOfInstances),
                label: 'Egyedi kártyák',
                hidden: !showTotalNrOfInstances
            },
            {
                data: this.lCYDashboardTimeSeriesStats.map(stat => stat.nrOfNewInstances),
                label: 'Új kártyák',
                hidden: !showNrOfNewInstances
            },
            {
                data: this.lCYDashboardTimeSeriesStats.map(stat => stat.totalNrOfStamps),
                label: 'Pecsételések',
                hidden: !showTotalNrOfStamps
            },
            {
                data: this.lCYDashboardTimeSeriesStats.map(stat => stat.totalNrOfRedeems),
                label: 'Beváltások',
                hidden: !showTotalNrOfRedeems
            }
        ];
    }

    setChartType(selectedChartType: string) {
        this.chartType = selectedChartType;
    }

    getTimeSeriesData(updateButtonPressed: boolean) {
        this.waitingForAPIResult = true;
        this.showLCYDashboardTimeSeriesStats = false;
        const deletedOptionSelected = this.getDeletedOptionSelected(updateButtonPressed);
        const virtualOptionSelected = this.getVirtualOptionSelected(updateButtonPressed);
        const stores = this.getStoresForRequestParams(deletedOptionSelected, virtualOptionSelected, updateButtonPressed);
        this.getLCYAnalyticsDasboardTimeSeriesDataRequest = {
            loyaltyCardYaltyIds: this.getLoyaltyCardsForRequestParams(updateButtonPressed),
            companyStoreIds: stores,
            isVirtualStoreSelected: virtualOptionSelected,
            periodStartTimestamp: this.timeSeriesPeriodStartTimestamp,
            periodEndTimestamp: this.timeSeriesPeriodEndTimestamp,
            aggregationType: this.timeSeriesAggregationType,
            frontendTimezone: 'Central European Standard Time'
        };
        this.httpService.post<GetLCYAnalyticsDasboardTimeSeriesDataResponse>('/partner/getLCYAnalyticsDasboardTimeSeriesData', this.getLCYAnalyticsDasboardTimeSeriesDataRequest)
            .subscribe((resp: GetLCYAnalyticsDasboardTimeSeriesDataResponse) => {
                if (resp.errorCode === 0) {
                    if (updateButtonPressed) this.saveGlobalFilters();
                    this.savedLoyaltyCardFilterStates['time'] = this.globalLoyaltyCardFilter;
                    this.savedStoreFilterStates['time'] = this.globalStoreFilter;
                    this.lCYDashboardTimeSeriesStats = resp.timeSeriesData;
                    this.timeSeriesChartDisplayable = false;
                    let timeSeriesChartAvailable = false;
                    for(let i = 0; i < this.lCYDashboardTimeSeriesStats.length; i++) {
                        if(this.lCYDashboardTimeSeriesStats[i].totalNrOfUsers != 0) {
                            timeSeriesChartAvailable = true;
                        }
                    }
                    this.timeSeriesChartDisplayable = timeSeriesChartAvailable;
                    this.setTimeSeriesDataSets(this.displayedStats.includes('totalNrOfUsers'), this.displayedStats.includes('nrOfNewUsers'), this.displayedStats.includes('totalNrOfInstances'), 
                    this.displayedStats.includes('nrOfNewInstances'), this.displayedStats.includes('totalNrOfStamps'), this.displayedStats.includes('totalNrOfRedeems'));
                    this.timeSeriesChartLabels = this.lCYDashboardTimeSeriesStats.map(stat => stat.label);
                    // Set chart color example
                    // this.timeSeriesChartColors = [
                    //     {
                    //         backgroundColor: '#00AEEF',
                    //         borderColor: '#00AEEF'
                    //     }
                    // ];
                    this.waitingForAPIResult = false;
                    //this.sectionsLoading[activeSection] = false;
                    this.showLCYDashboardTimeSeriesStats = true;
                } else {
                    this.waitingForAPIResult = false;
                    this.dialogService.openDialog('Szerver hiba. Kérjük próbálja meg később.',
                        '', 'Rendben', undefined, '450px');
                }
            });
    }

    get displayedStatsField(): AbstractControl { return this.displayedStatsForm.get('displayedStats')!; }
    get displayedStats(): (string)[] { return this.displayedStatsField.value; }

    get storeFilterField(): AbstractControl { return this.filtersForm.get('storeFilter')!; }
    get storeFilter(): (string | number)[] { return this.storeFilterField.value; }

    get loyaltyCardFilterField(): AbstractControl { return this.filtersForm.get('loyaltyCardFilter')!; }
    get loyaltyCardFilter(): (string | number)[] { return this.loyaltyCardFilterField.value; }

    get timeSeriesPeriodField(): AbstractControl { return this.timeSeriesForm.get('period')!; }
    get timeSeriesPeriod(): number { return this.timeSeriesPeriodField.value; }

    get timeSeriesPeriodStartTimestampField(): AbstractControl { return this.timeSeriesForm.get('periodStartTimestamp')!; }
    get timeSeriesPeriodStartTimestamp(): string | null { return this.timeSeriesPeriodStartTimestampField.value; }

    get timeSeriesPeriodEndTimestampField(): AbstractControl { return this.timeSeriesForm.get('periodEndTimestamp')!; }
    get timeSeriesPeriodEndTimestamp(): string | null { return this.timeSeriesPeriodEndTimestampField.value; }

    get timeSeriesAggregationTypeField(): AbstractControl { return this.timeSeriesForm.get('aggregationType')!; }
    get timeSeriesAggregationType(): AggregationType { return this.timeSeriesAggregationTypeField.value; }

    //get sectionSelector(): keyof SectionNames { return this.sectionSelectorField.value; }
    get activeStoreFilter(): boolean { return this.activeStoreFilterField.value; }

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => {
            sub.unsubscribe();
        })
    }

}

/*interface SectionNames {
    dashboard: boolean;
    time: boolean;
    other: boolean;
    customer: boolean;
}*/

interface SectionStates {
    dashboard: (string | number)[];
    time: (string | number)[];
    other: (string | number)[];
    customer: (string | number)[];
}
