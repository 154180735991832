// @ts-nocheck

angular.module('yaltyApp')
    .controller('loyaltyCardRegistration68Ctrl', function ($scope, $timeout, $rootScope, $http, $state, $location, API_PREFIX, AuthService, $mdDialog, GoogleAnalyticsService, $cookies, $window, $filter) {
        if ($state.params.lang != null) {
            $scope.lang = $state.params.lang;
        }
        if (window.i18next) {
            window.i18next.use(window.i18nextXHRBackend);
            window.i18next.init({
                debug: false,
                lng: $scope.lang,
                fallbackLng: 'hu',
                backend: {
                    loadPath: '/api/translations/web_app_translation_{{lng}}.json'
                }
            }, function (err, t) {
                $scope.title = i18next.t('loyalty_cards.68.name');
                $scope.emailText = i18next.t('form.email.label');
                $scope.emailInvalidText = i18next.t('form.email.invalid');
                $scope.firstNameText = i18next.t('form.user.firstName');
                $scope.lastNameText = i18next.t('form.user.lastName');
                $scope.ok = i18next.t('generic.ok');
                $scope.server_error = i18next.t('generic.server_error');
                $scope.general_terms_and_conditions1 = i18next.t('form.general_terms_and_conditions.text_part1');
                $scope.general_terms_and_conditions2 = i18next.t('form.general_terms_and_conditions.text_part2');
                $scope.general_terms_and_conditions3 = i18next.t('form.general_terms_and_conditions.text_part3');
                $scope.required = i18next.t('form.required');
                if($state.params.insid != null){
                    $scope.button = i18next.t('form.modify_data.button');
                    $scope.successTitle = i18next.t('form.modify_data.success.title');
                    $scope.successText = i18next.t('form.modify_data.success.text');
                    $scope.unsuccessTitle = i18next.t('form.modify_data.unsuccess.title');
                }else{
                    $scope.button = i18next.t('form.registration.button');
                    $scope.successTitle = i18next.t('form.registration.success.title');
                    $scope.successText = i18next.t('form.registration.success.text');
                    $scope.unsuccessTitle = i18next.t('form.registration.unsuccess.title');
                    $scope.registrationMissingTermsAndConditions = i18next.t('form.registration.missing_terms_and_conditions');
                }
                $scope.$apply();
            })
        }
        if ($state.params.fname != null) {
            $scope.firstName = $state.params.fname;
        }
        if ($state.params.lname != null) {
            $scope.lastName = $state.params.lname;
        }
        if ($state.params.email != null) {
            $scope.emailAddress = $state.params.email;
        }
        if ($state.params.token != null) {
            $scope.token = $state.params.token;
        }
        if ($state.params.insid != null) {
            $scope.instanceId = $state.params.insid;
            $scope.loadingInProgress = true;
            let data = {
                token: $scope.token,
                loyaltyCardInstanceId: $state.params.insid
            };
            $http.post(API_PREFIX.url + '/loyaltyCard/getLoyaltyCardInstanceRegistrationData', data).then(function (result) {
                $scope.loadingInProgress = false;
                if (result.data.errorCode == 0) {
                    $scope.lastName = result.data.lastName;
                    $scope.firstName = result.data.firstName;
                    $scope.emailAddress = result.data.emailAddress;
                    $scope.customerLoyaltyId = result.data.customerLoyaltyId;
                }
                else {
                    const alert = $mdDialog.alert()
                    .title($scope.unsuccess)
                    .textContent($scope.server_error)
                    .ok($scope.ok)
                    $mdDialog.show(alert);
                }
            }, function () {
                $scope.loadingInProgress = false;
                const alert = $mdDialog.alert()
                .title($scope.unsuccess)
                .textContent($scope.server_error)
                .ok($scope.ok)
                $mdDialog.show(alert);
            });
        }
        $scope.registration = function () {
            $scope.registrationInProgress = true;
            if (checkFormForErrors()) {
                $scope.registrationInProgress = false;
            } else {
                let data = {};
                if ($state.params.insid != null) {
                    data = {
                        loyaltyCardInstanceId: $state.params.insid,
                        token: $scope.token,
                        cardRegistrationType: 1,
                        companyId: 512,
                        loyaltyCardId: 68,
                        customerLoyaltyId: $scope.customerLoyaltyId,
                        modifiedRegistrationData: {
                            lastName: $scope.lastName,
                            firstName: $scope.firstName,
                            emailAddress: $scope.emailAddress
                        }
                    };
                } else {
                    data = {
                        token: $scope.token,
                        cardRegistrationType: 1,
                        companyId: 512,
                        loyaltyCardId: 68,
                        scannedBarcodeType: 1,
                        scannedBarcodeSubType: 21,
                        customerLoyaltyIdPrefix: '5996268',
                        customLoyaltyIdVariableLength: 6,
                        initPointBalance: 0,
                        initCurrencyBalance: null,
                        initLoyaltyLevelBalance: null,
                        customerLoyaltyId: $scope.customerLoyaltyId,
                        registrationData: {
                            lastName: $scope.lastName,
                            firstName: $scope.firstName,
                            emailAddress: $scope.emailAddress
                        }
                    };
                }
                $http.post(API_PREFIX.url + '/loyaltyCard/addOrUpdateLoyaltyCardInstance_v3', data).then(function (result) {
                    $scope.registrationInProgress = false;
                    if (result.data.errorCode == 0) {
                            const alert = $mdDialog.alert()
                                .title($scope.successTitle)
                                .textContent($scope.successText)
                                .ok($scope.ok)
                            $mdDialog.show(alert).then(function () {
                                if ($state.params.insid != null) {location.href = "service/loyaltyCardRegistrationDataModifiedSuccessful";}
                                else location.href = "service/loyaltyCardRegistrationSuccessful?id=" + result.data.registeredLoyaltyCardInstanceId;
                            });
                    } else {
                        const alert = $mdDialog.alert()
                        .title($scope.unsuccess)
                        .textContent($scope.server_error)
                        .ok($scope.ok)
                        $mdDialog.show(alert);
                    }
                }, function () {
                    $scope.registrationInProgress = false;
                    const alert = $mdDialog.alert()
                        .title($scope.unsuccess)
                        .textContent($scope.server_error)
                        .ok($scope.ok)
                    $mdDialog.show(alert);
                });
            }
        }

        function checkFormForErrors() {
            if ($state.params.insid == null) {
                var validEmailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if ($scope.emailAddress == undefined || $scope.lastName == undefined || $scope.firstName == undefined) {
                    const alert = $mdDialog.alert()
                        .title("")
                        .textContent($scope.required)
                        .ok($scope.ok)
                    $mdDialog.show(alert);
                    return true;
                } else if ($scope.acceptedGeneralConditions != true) {
                    const alert = $mdDialog.alert()
                        .title("")
                        .textContent($scope.registrationMissingTermsAndConditions)
                        .ok($scope.ok)
                    $mdDialog.show(alert);
                    return true;
                } else if (!validEmailFormat.test($scope.emailAddress)) {
                    const alert = $mdDialog.alert()
                        .title("")
                        .textContent($scope.emailInvalidText)
                        .ok($scope.ok)
                    $mdDialog.show(alert);
                    return true;
                }
            } else {
                return false;
            }

        }
    })