// @ts-nocheck

angular.module('yaltyApp')
    .controller('userAccountCtrl', function ($scope, $rootScope, $window, $filter, $state, $cookies, $document, $mdDialog, API_PREFIX, UserAccountService, AuthService, SubscriptionNames, $http) {
        var virtualSize = window.innerHeight - 340;
        $scope.containerSize = "height: " + virtualSize + "px;width: 300px";
        $scope.formHeight = { 'min-height': virtualSize + 'px' }
        window.onresize = function(event) {
            virtualSize = window.innerHeight - 340;
            $scope.containerSize = "height: " + virtualSize + "px;width: 300px";
            $scope.formHeight = { 'min-height': virtualSize + 'px' }
        };
        const userAccountCount = $cookies.get('sbcrtyp').split('&')[3];
        const currentUserEmail = $cookies.get('userEmail');
        $scope.typePassword = true;
        $scope.togglePassword = function () {
            $scope.typePassword = !$scope.typePassword;
         };
        $scope.sendButtonDisabled = false;
        $scope.showStores = true;
        $scope.buttonClass = "activeBtn";
        $scope.showSetStore = false;
        $scope.isOpened = "fa fa-chevron-up";
        $scope.showSetPassword = false;
        $scope.showSetNewPassword = false;
        $scope.showSetPermission = false;
        $scope.newButtonClass = "btn";
        $scope.newButtonClass1 = "btn";
        $scope.animateMobile = "";
        $scope.isMasterUserModify = false;
        $scope.orderBy = [{ id: '1', value: 'Felhasználó megnevezése szerint A-Z', order: 'lastName' },
        { id: '2', value: 'Felhasználó megnevezése szerint Z-A', order: 'lastName' }];
        
        $scope.orderChange = function (id) {
            if (id % 2 == 0) {
                $scope.orderUser = "'" + $scope.orderBy[(id - 1)].order + "'";
                $scope.reverseUser = true;
            } else {
                $scope.orderUser = "'" + $scope.orderBy[(id - 1)].order + "'";
                $scope.reverseUser = false;
            }
        }

        $scope.order = $scope.orderBy[0];
        $scope.orderUser = "'" + $scope.orderBy[0].order + "'";
        $scope.reverseUser = false;
        $scope.orderChange(1);
        if ($scope.allUser == (undefined || null)) {
            $scope.errUser = "Nincsenek felhasználói fiókok.";
        }
        let resetVariables = () =>{
            $rootScope.isDataSheetModified = false;
            $scope.isPermissionDisabled = false;
            $scope.isMasterUserModify = false;
            if ($scope.userAccountForm) {
                $scope.userAccountForm.$setUntouched();
                $scope.userAccountForm.$setPristine();
            }
            $scope.mainContactEmail = undefined;
            $scope.contactPhoneNumber = undefined;
            $scope.mainContactNameTitle = undefined;
            $scope.mainContactNameLastName = undefined;
            $scope.mainContactNameFirstName = undefined;
            $scope.password = undefined;
            $scope.newPassword = undefined;
            $scope.newPasswordAgain = undefined;
            $scope.permission = undefined;
        }
        $scope.resendActivationEmail = function (id, userName){
            var userAccount = {};
            userAccount.userName = userName;
            userAccount.partnerUserId = id;
            UserAccountService.resendActivationEmail(userAccount).then(function (result){
                if (result.errorCode == 0) {
                    const alert = $mdDialog.alert()
                        .title(result.msg)
                        .textContent(result.msg)
                        .ok('Rendben')
                    $mdDialog.show(alert).then(function () {
                        $state.go('home.users', {}, { reload: true });
                    })
                } else {
                    const alert = $mdDialog.alert()
                        .title(result.msg)
                        .textContent(result.msg)
                        .ok('Rendben')
                    $mdDialog.show(alert).then(function () {
                    })
                }
            }, function (result) {
                const alert = $mdDialog.alert()
                    .title(result.msg)
                    .textContent(result.msg)
                    .ok('Rendben')
                $mdDialog.show(alert).then(function () {
                    
                })
            });
        }
        $scope.sendUserAccountsData = function (form) {
            $scope.sendButtonDisabled = true;
            if (!form.$valid) {
                if (angular.element($document[0].querySelector('input.ng-invalid')).length > 0) {
                    angular.element($document[0].querySelector('input.ng-invalid')).focus();
                } else if (angular.element($document[0].querySelector('md-select.ng-invalid')).length > 0) {
                    angular.element($document[0].querySelector('md-select.ng-invalid')).focus();
                } else {
                    angular.element($document[0].querySelector('textarea.ng-invalid')).focus();
                }
                $scope.sendButtonDisabled = false;
                $rootScope.isDataSheetModified = true;
                return false;
            } else {
                if (userAccountCount != 0 && $scope.changeType == "register" && ($rootScope.partnerSubscriptionType == 'yalty_start_free' && ($scope.allUser.length + 1) >= userAccountCount
                || $rootScope.partnerSubscriptionType == 'yalty_start_paid' && ($scope.allUser.length + 1) >= userAccountCount
                || $rootScope.partnerSubscriptionType == 'basic' && ($scope.allUser.length + 1) >= userAccountCount
                || $rootScope.partnerSubscriptionType == 'premium' && ($scope.allUser.length + 1) >= userAccountCount)) {
                    $scope.sendButtonDisabled = false;
                    $rootScope.isDataSheetModified = true;
                    $mdDialog.show({
                        template:
                            '<md-dialog>' +
                            '  <md-dialog-content class="md-dialog-content">'+
                            'Elérte a jelenlegi előfizetési csomagja (<b>{{partnerSubscriptionName}}' +
                            '</b>) szerinti létrehozható felhasználók maximális számát ({{count}} db felhasználó a saját fiókjával együtt).' + 
                            'Törölje ki az egyik felhasználót vagy váltson magasabb előfizetési csomagra. ' +
                            '<a ng-click="navigate()">Az előfizetések áttekintéséhez és módosításához kattintson ide.</a>' +
                            '  </md-dialog-content>' +
                            '  <md-dialog-actions>' +
                            '    <md-button ng-click="closeDialog()" class="md-primary">' +
                            '      Rendben' +
                            '    </md-button>' +
                            '  </md-dialog-actions>' +
                            '</md-dialog>',
                        locals: {
                            partnerSubscriptionType: $rootScope.partnerSubscriptionType,
                            partnerSubscriptionName: SubscriptionNames[$rootScope.partnerSubscriptionType],
                            accountCount: userAccountCount
                        },
                        controller: DialogCtrl
                    });
                } else {
                    var userAccount = {};
                    userAccount.nameTitle = $scope.mainContactNameTitle;
                    userAccount.firstName = $scope.mainContactNameFirstName;
                    userAccount.lastName = $scope.mainContactNameLastName;
                    userAccount.userName = $scope.mainContactEmail;
                    userAccount.authorizationLevel = $scope.permission;
                    userAccount.userId = $scope.userId;
                    userAccount.changeType = $scope.changeType;
                    userAccount.currentPassword = $scope.password;
                    if ($scope.newPassword){
                        userAccount.newPassword = $scope.newPassword;
                    } else {
                        userAccount.newPassword = null;
                    }
                    if ($scope.contactPhoneNumber){
                        userAccount.contactPhoneNumber = $scope.contactPhoneNumber;
                    } else {
                        userAccount.contactPhoneNumber = null;
                    }
                    $scope.saveInProgress = true;
                    UserAccountService.sendUserAccountData(userAccount).then(function (result) {
                        $scope.saveInProgress = false;
                        if (result.errorCode == 0) {
                            let alert
                            if ($scope.changeType == 'register') {
                                alert = $mdDialog.alert()
                                    .title('A felhasználói fiókot sikeresen létrehoztuk!')
                                    .textContent('A megadott email címre aktivációs levelet küldtünk. A felhasználó a levélben található linkre kattintva tudja jelszavát beállítani és fiókját aktiválni.')
                                    .ok('Rendben')
                            } else {
                                alert = $mdDialog.alert()
                                    .title('A felhasználói fiók adatait sikeresen elmentettük!')
                                    .ok('Rendben')
                            }
                            $mdDialog.show(alert).then(function () {
                                $scope.sendButtonDisabled = false;
                                $rootScope.isDataSheetModified = false;
                                $state.reload();
                            });
                        } else if (result.errorCode == 3){
                            $scope.sendButtonDisabled = false;
                            $rootScope.isDataSheetModified = true;
                            $mdDialog.show({
                                template:
                                    '<md-dialog>' +
                                    '  <md-dialog-content class="md-dialog-content">'+
                                    'Elérte a jelenlegi előfizetési csomagja (<b>{{partnerSubscriptionName}}' +
                                    '</b>) szerinti létrehozható felhasználók maximális számát ({{count}} db felhasználó a saját fiókjával együtt).' + 
                                    'Törölje ki az egyik felhasználót vagy váltson magasabb előfizetési csomagra. ' +
                                    '<a ng-click="navigate()">Az előfizetések áttekintéséhez és módosításához kattintson ide.</a>' +
                                    '  </md-dialog-content>' +
                                    '  <md-dialog-actions>' +
                                    '    <md-button ng-click="closeDialog()" class="md-primary">' +
                                    '      Rendben' +
                                    '    </md-button>' +
                                    '  </md-dialog-actions>' +
                                    '</md-dialog>',
                                locals: {
                                    partnerSubscriptionType: $rootScope.partnerSubscriptionType,
                                    partnerSubscriptionName: SubscriptionNames[$rootScope.partnerSubscriptionType],
                                    accountCount: userAccountCount
                                },
                                controller: DialogCtrl
                            });
                        } else if (result.errorCode == 4){
                            const alert = $mdDialog.alert()
                                .title("A megadott jelszó érvénytelen")
                                .textContent("A megadott aktuális jelszó érvénytelen, kérjük ismételd meg a folyamatot.")
                                .ok('Rendben')
                            $mdDialog.show(alert).then(function () {
                                $scope.sendButtonDisabled = false;
                                $rootScope.isDataSheetModified = true;
                            })
                        } else {
                            const alert = $mdDialog.alert()
                                .title(result.msg)
                                .textContent(result.msg)
                                .ok('Rendben')
                            $mdDialog.show(alert).then(function () {
                                $scope.sendButtonDisabled = false;
                                $rootScope.isDataSheetModified = true;
                            })
                        }
                    }, function (result) {
                        $scope.saveInProgress = false;
                        var alert = $mdDialog.alert()
                            .title(result.msg)
                            .textContent(result.msg)
                            .ok('Rendben')
                        $mdDialog.show(alert).then(function () {
                            $scope.sendButtonDisabled = false;
                            $rootScope.isDataSheetModified = true;
                        })
                    });
                }
            }
        }
        $scope.newUserChange = function () {
            $scope.sendButtonDisabled = false;
            $rootScope.isDataSheetModified = true;
            if ($scope.mainContactNameFirstName != null && $scope.mainContactNameLastName != null) {
                if($scope.mainContactNameTitle == null || angular.isUndefined($scope.mainContactNameTitle)){
                    $scope.newUser = $scope.mainContactNameLastName + ' ' + $scope.mainContactNameFirstName;
                }else{
                    $scope.newUser = $scope.mainContactNameTitle + ' ' + $scope.mainContactNameLastName + ' ' + $scope.mainContactNameFirstName;
                }
            } else {
                $scope.newUser = "ÚJ FELHASZNÁLÓ";
            }
        }
        $scope.removeUser = function (ev) {
            if($scope.permission == null){
                //DO NOTHING
            } else if($scope.permission == 'master'){
                const confirm = $mdDialog.confirm()
                .title('Biztosan törölni szeretné partneri fiókját?')
                .textContent('Az Ön fiókja a céghez tartozó mester fiók. Amennyiben folytatja és törli a fiókját, akkor ezzel egyben a teljes céges, partneri fiókját is töröljük. Igen válasz esetén a törléshez szükséges linket a kapcsolattartási (mester) fiók email címére küldjük.')
                .ariaLabel('Törlés megerősítése')
                .targetEvent(ev)
                .ok('Igen')
                .cancel('Mégsem');
                $mdDialog.show(confirm).then(function () {
                    var company = {};
                    company.companyId = $scope.compData.id;
                    $scope.removeInProgress = true;
                    $http.post(API_PREFIX.url + "/partner/sendPartnerDeleteLink", company).then(function (result) {
                        $scope.removeInProgress = false;
                        if (result.data.errorCode == 0) {
                            $mdDialog.show(
                                $mdDialog.alert()
                                    .title('A törléshez szükséges linket kiküldtük a kapcsolattartási (mester) fiók email címére.')
                                    .ok('Rendben')
                                    .targetEvent(ev)
                            );
                        } else if (result.data.errorCode == 1) {
                            $mdDialog.show(
                                $mdDialog.alert()
                                    .title('Az adatbázis elérése sikertelen, kérjük próbálja meg később.')
                                    .ok('Rendben')
                                    .targetEvent(ev)
                            );
                        } else if (result.data.errorCode == 2) {
                            $mdDialog.show(
                                $mdDialog.alert()
                                    .title('A művelet elvégzéséhez önnek nincs jogosultsága.')
                                    .ok('Rendben')
                                    .targetEvent(ev)
                            );
                        }
                    })
                }, function () {
                });
            } else {
                const confirm = $mdDialog.confirm()
                .title('Biztosan törölni szeretné a felhasználót?')
                .textContent('Igen válasz esetén a felhasználó törlésre kerül.')
                .ariaLabel('Törlés megerősítése')
                .targetEvent(ev)
                .ok('Igen')
                .cancel('Mégsem');
                $mdDialog.show(confirm).then(function () {
                    var user = {};
                    user.userId = $scope.userId;
                    $scope.removeInProgress = true;
                    UserAccountService.removeUser(user).then(function (result) {
                        $scope.removeInProgress = false;
                        $mdDialog.show(
                            $mdDialog.alert()
                                .clickOutsideToClose(true)
                                .title(result.data.msg)
                                .ok('Rendben')
                        ).then(()=>{
                            if (!$scope.userId) {
                                AuthService.logout();
                            } else {
                                $state.reload();
                            }
                        })
                    }, function (result) {
                        $scope.removeInProgress = false;
                        $mdDialog.show(
                            $mdDialog.alert()
                                .clickOutsideToClose(true)
                                .title(result.data.msg)
                                .ok('Rendben')
                                .targetEvent(ev)
                        )
                    })
                }, function () {
                });
            }
        }
        $scope.checkNewPassword = function(){
            if($scope.newPassword){
                $scope.isSetNewPassword = true;
                $scope.sendButtonDisabled = false;
                $rootScope.isDataSheetModified = true;
            } else{
                $scope.isSetNewPassword = false;
                $scope.sendButtonDisabled = true;
                $rootScope.isDataSheetModified = false;
            }
        }
        $scope.isContactPhoneNumberSame = function (){
            if($scope.contactPhoneNumber == $scope.userData.partnerUserData[0].contactPhoneNumber){
                $scope.sendButtonDisabled = true;
                $rootScope.isDataSheetModified = false;
            }else{
                $scope.sendButtonDisabled = false;
                $rootScope.isDataSheetModified = true;
            }

        }
        $scope.modifyUser = function (id, email) {
            if (email == currentUserEmail) {
                $scope.setUserAccount('modify')
            } else {
                resetVariables();
                var user = {};
                user.partnerUserId = id;
                var date = new Date();
                var year = $filter('date')(date, "yyyy");
                var month = $filter('date')(date, "MM");
                var day = $filter('date')(date, "dd");
                var monthInHungarian = getMonth(month);
                $scope.modifiedDate = year + ". " + monthInHungarian + " " + day + ".";
                UserAccountService.getUserData(user).then(function (result) {
                    $scope.userId = id;
                    $scope.changeType = "update";
                    $scope.buttonClass = "activeBtn";
                    $scope.newButtonClass = "btn";
                    $scope.newButtonClass1 = "btn";
                    $scope.openVirtual = "margin-top: 30px;";
                    $scope.mainContactEmail = result.partnerUserData[0].userName;
                    $scope.permission = result.partnerUserData[0].authorizationLevel;
                    $scope.mainContactNameTitle = result.partnerUserData[0].nameTitle;
                    $scope.mainContactNameFirstName = result.partnerUserData[0].firstName;
                    $scope.mainContactNameLastName = result.partnerUserData[0].lastName;
                    if($scope.mainContactNameTitle == null){
                        $scope.newUser = $scope.mainContactNameLastName + ' ' + $scope.mainContactNameFirstName;
                    }else{
                        $scope.newUser = $scope.mainContactNameTitle + ' ' + $scope.mainContactNameLastName + ' ' + $scope.mainContactNameFirstName;
                    }
                    $scope.showSetPassword = false;
                    $scope.showSetNewPassword = false;
                    $scope.showSetPermission = true;
                    $scope.isNewOrUpdateUser = false;
                    $scope.showSetAccountData = true;
                    //$scope.showStores = true;
                    $scope.isOpened = "fa fa-chevron-up";
                    $scope.showSetStore = true;
                    $scope.sendButtonDisabled = true;
                    $rootScope.isDataSheetModified = false;
                });
            }
        }
        $scope.setUserAccount = function (param) {
            resetVariables();
            if (param == "new") {
                $scope.sendButtonDisabled = true;
                $rootScope.isDataSheetModified = false;
                $scope.userId = undefined;
                $scope.changeType = "register";
                $scope.buttonClass = "btn";
                $scope.newButtonClass = "activeBtn";
                $scope.newButtonClass1 = "btn";
                $scope.showSetPassword = false;
                $scope.showSetNewPassword = false;
                $scope.showSetAccountData = true;
                $scope.showSetPermission = true;
                $scope.isNewOrUpdateUser = true;
                $scope.mainContactEmail = undefined;
                $scope.permission = undefined;
                $scope.mainContactNameTitle = undefined;
                $scope.mainContactNameFirstName = undefined;
                $scope.mainContactNameLastName = undefined;
                $scope.contactPhoneNumber = undefined;
                $scope.newPassword = undefined;
                $scope.newUser = "ÚJ FELHASZNÁLÓ";
            } else {
                $scope.sendButtonDisabled = true;
                $rootScope.isDataSheetModified = false;
                var user = {};
                user.partnerUserId = undefined;
                $scope.mainContactNameTitle = $scope.userData.partnerUserData[0].nameTitle;
                $scope.mainContactNameFirstName = $scope.userData.partnerUserData[0].firstName;
                $scope.mainContactNameLastName = $scope.userData.partnerUserData[0].lastName;
                $scope.mainContactEmail = $scope.userData.partnerUserData[0].userName;
                if($scope.mainContactNameTitle == null){
                    $scope.newUser = $scope.mainContactNameLastName + ' ' + $scope.mainContactNameFirstName;
                }else{
                    $scope.newUser = $scope.mainContactNameTitle + ' ' + $scope.mainContactNameLastName + ' ' + $scope.mainContactNameFirstName;
                }
                $scope.permission = $scope.userData.partnerUserData[0].authorizationLevel;
                $scope.contactPhoneNumber = $scope.userData.partnerUserData[0].contactPhoneNumber;
                $scope.userId = undefined;
                $scope.changeType = "update";
                $scope.buttonClass = "btn";
                $scope.newButtonClass = "btn";
                $scope.newButtonClass1 = "activeBtn";
                $scope.showSetAccountData = true;
                $scope.showSetNewPassword = false;
                $scope.showSetPassword = true;
                $scope.showSetPermission = true;
                $scope.isNewOrUpdateUser = false;
                $scope.isMasterUserModify = $scope.userData.partnerUserData[0].authorizationLevel == 'master' ? true : false;
                $scope.isPermissionDisabled = true;
            }
            var date = new Date();
            var year = $filter('date')(date, "yyyy");
            var month = $filter('date')(date, "MM");
            var day = $filter('date')(date, "dd");
            var monthInHungarian = getMonth(month);
            $scope.modifiedDate = year + ". " + monthInHungarian + " " + day + ".";
            if ($scope.authorizationLevel == "redeemer") {
                $scope.animateMobile = "";
                $scope.openVirtual = "";
            } else {
                if ($scope.showStores == false) {
                    $scope.animateMobile = "animateToUserAccounts";
                    $scope.openVirtual = "margin-top: 30px;margin-left: -20px";
                } else {
                    $scope.animateMobile = "animateFromUserAccounts"
                    $scope.openVirtual = "margin-top: 30px;";
                }
            }
            $scope.isOpened = "fa fa-chevron-up";
            $scope.showSetStore = true;
        }
        $scope.getUserAccounts = function () {
            if ($scope.showStores) {
                $scope.buttonClass = "btn";
                $scope.animateMobile = "animateToUserAccounts";
                $scope.showStores = false;
                $scope.isOpened = "fa fa-chevron-down";
                $scope.openVirtual = "margin-top: 30px;margin-left: -20px";
            } else {
                $scope.buttonClass = "activeBtn";
                $scope.animateMobile = "animateFromUserAccounts"
                $scope.showStores = true;
                $scope.isOpened = "fa fa-chevron-up";
                $scope.openVirtual = "margin-top: 30px;";
            }
        }
        function getMonth(month) {
            if (month == "01") {
                return "január";
            } else if (month == "02") {
                return "február";
            } else if (month == "03") {
                return "március";
            } else if (month == "04") {
                return "április";
            } else if (month == "05") {
                return "május";
            } else if (month == "06") {
                return "június";
            } else if (month == "07") {
                return "július";
            } else if (month == "08") {
                return "augusztus";
            } else if (month == "09") {
                return "szeptember";
            } else if (month == "10") {
                return "október";
            } else if (month == "11") {
                return "november";
            } else if (month == "12") {
                return "december";
            }
        }
        
        if($scope.authorizationLevel == "redeemer"){
            $scope.setUserAccount('modify');
        }
        function DialogCtrl($scope, $mdDialog, partnerSubscriptionName, partnerSubscriptionType, accountCount) {
            $scope.partnerSubscriptionType = partnerSubscriptionType;
            $scope.partnerSubscriptionName = partnerSubscriptionName;
            $scope.count = accountCount;
            $scope.closeDialog = function() {
                $mdDialog.hide();
            }
            $scope.navigate = function () {
                $mdDialog.hide();
                $state.go("home.subscription")
            }
        }
    })