// @ts-nocheck

function partnerAndStoreDetailViewController() {
    var ctrl = this;
    ctrl.$onChanges = function (changes) {
        if (changes.detailViewImgURL) {
            if (ctrl.detailViewImgURL) {
                ctrl.backgroundStyle = { 'background-image': `url(${ctrl.detailViewImgURL})` }
            } else {
                ctrl.backgroundStyle = {
                    'background-image': `url('/imgs/yalty_logo_white.svg')`,
                    'background-color': '#E5E8EB',
                    'background-size': '70px'
                }
            }
        }
    }
}

angular.module('yaltyApp')
    .component('partnerAndStoreDetailView', {
        templateUrl: 'js/components/partnerAndStoreDetailView.html?v=202405171545',
        controller: partnerAndStoreDetailViewController,
        bindings: {
            detailViewImgURL: '<',
            companyLogoURL: '<',
            compNamePromo: '<',
            description: '<',
            favouriteEnabled: '<',
            isOnlineStore: '<',
            onlineStoreRedeemInfo: '<',
            city: '<',
            street: '<',
            addrNo: '<',
            zip: '<',
            phoneNumber: '<',
            webPage: '<',
            fbPage: '<'
        }
    });