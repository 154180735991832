<div class="aggregate-stores-component default-srolling-design">
    <div id="currentStateDiv">
        <h2>{{ currentState }}
            <i class="fa fa-chevron-right" style="font-size:80%"></i> {{ currentSubState }}
        </h2>
    </div>

    <div id="button-container">
        <button mat-flat-button color="primary" [disabled]="waitingForAllStoreAPIResult" (click)="getAllCompanyStore()"
            class="list-all-store-button">
            <span [hidden]="waitingForAllStoreAPIResult">Üzletek listázása</span>
            <img *ngIf="waitingForAllStoreAPIResult" class="loading" src="imgs/loading_indicator.gif">
        </button>
        <button mat-flat-button color="primary" (click)="openStoreWindow()" class="add-new-store-button">
            <span>Új üzlet felvétele</span>
        </button>
    </div>

    <form [formGroup]="filtersForm">
        <div id="filters-form">
            <app-input-text [inputName]="'storeNameFilter'" [label]="'Üzlet neve'" [maxLength]="100"
                class="input-narrow"></app-input-text>
            <app-input-text [inputName]="'externalStoreIdFilter'" [label]="'Külső azonosító'" [maxLength]="20"
                class="input-narrow"></app-input-text>
            <app-input-text [inputName]="'storeAddrZIPFilter'" [label]="'Irányítószám'" [maxLength]="10"
                class="input-narrow"></app-input-text>
            <app-autocomplete [inputName]="'storeAddrCityFilter'" [label]="'Város'" [options]="allCity"
                [onlyValidFromList]="false" class="input-narrow"></app-autocomplete>
            <button mat-flat-button color="primary" (click)="resetFilters()">
                <span>Szűrők törlése</span>
            </button>
        </div>
    </form>

    <div [hidden]="!showTable">
        <div id="sort-form-container">
            <div class="display-store-number">Megjelenített üzletek száma: <b>{{filteredCompanyStores?.length}}</b>
                (Összes
                üzlet:
                <b>{{allCompanyStore?.length}}</b>)
            </div>
            <form [formGroup]="sortForm" class="sort-form">
                <div>
                    <app-select [inputName]="'sort'" [label]="'Rendezés'" [objArray]="sortOptions" class="gray-1-theme">
                    </app-select>
                </div>
            </form>
        </div>
        <cdk-virtual-scroll-viewport itemSize="50" minBufferPx="1000" maxBufferPx="2000" class="cdk-viewport"
            [ngStyle]="storeListHeight">
            <table class="default-design all-store-list" #table>
                <tr *ngIf="filteredCompanyStores?.length > 0">
                    <th class="row-number-column">#</th>
                    <th *ngFor="let column of storeTableHeader" [ngStyle]="column.style">
                        {{column.label}}
                    </th>
                    <th class="modify-column">Módosítás</th>
                    <th class="delete-column">Törlés</th>
                </tr>
                <!-- <tr *ngFor="let store of filteredCompanyStores; let i = index"> -->
                <tr *cdkVirtualFor="let store of filteredCompanyStores; let i = index">
                    <td class="index">{{(i + 1)}}</td>
                    <td *ngFor="let column of storeTableHeader">
                        {{store[column.contentRef] ? store[column.contentRef] : '-'}}
                    </td>
                    <td><img src="imgs/edit_icon.svg" height="14" (click)="openStoreWindow(store.id)"></td>
                    <td>
                        <div [hidden]="waitingForDeleteAPIResult[i]">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" (click)="deletionButtonClicked(store.id, i)"
                                viewBox="0 0 19.656 21.84">
                                <g id="delete-4" transform="translate(-127.235 -2)" opacity="0.8">
                                    <path id="Path_6809" data-name="Path 6809"
                                        d="M133.787,5.276V4.73A2.731,2.731,0,0,1,136.517,2h1.092a2.729,2.729,0,0,1,2.73,2.73v.546h5.46a1.092,1.092,0,1,1,0,2.184h-1.092V19.472a4.367,4.367,0,0,1-4.368,4.368h-6.552a4.368,4.368,0,0,1-4.368-4.368V7.46h-1.092a1.092,1.092,0,0,1,0-2.184Zm8.736,2.184H131.6V19.472a2.184,2.184,0,0,0,2.184,2.184h6.552a2.183,2.183,0,0,0,2.184-2.184Zm-4.368,3.276V18.38a1.092,1.092,0,0,0,2.184,0V10.736a1.092,1.092,0,0,0-2.184,0Zm-4.368,0V18.38a1.092,1.092,0,0,0,2.184,0V10.736a1.092,1.092,0,0,0-2.184,0Zm4.368-5.46V4.73a.544.544,0,0,0-.546-.546h-1.092a.546.546,0,0,0-.546.546v.546Z"
                                        transform="translate(0 0)" fill="#4E525C" fill-rule="evenodd" />
                                </g>
                            </svg>
                        </div>
                        <img *ngIf="waitingForDeleteAPIResult[i]" class="loading" src="imgs/loading_indicator.gif">
                    </td>
                </tr>
            </table>
        </cdk-virtual-scroll-viewport>
    </div>

    <div class="popup-overlay" *ngIf="showStoreWindow">
        <div class="popup-window">
            <div class="popup-window_header">{{storeWindowTitle}}</div>
            <div class="popup-window_content">
                <form [formGroup]="aggregateStoreWindowForm">
                    <div class="popup-window_block">
                        <app-input-text [inputName]="'externalStoreId'" [label]="'Külső azonosító'" [maxLength]="20"
                            class="input-narrow">
                        </app-input-text>
                        <app-input-text [inputName]="'storeName'" [label]="'Üzlet neve'" [maxLength]="100"
                            class="input-extra-wide">
                        </app-input-text>
                        <app-checkbox [inputName]="'storeNameSameAsCo'" class="gray-1-theme"
                            [label]="'Azonos a cég nevével'">
                        </app-checkbox>
                    </div>
                    <div class="popup-window_block">
                        <!-- <div class="tooltip-container section-title">
                            <h3>Üzlet címe</h3>
                            <i class="fa fa-info-circle tooltip">
                                <span class="yaltytiptextright tooltip-right">
                                    <p>
                                        Különböző üzletek címei egyedi, különböző címek kell legyenek.
                                        Ha már létezik üzlete ugyanezzel az itt megadott címmel (tehát
                                        ha az alábbi mezők teljes egyezést mutatnak egy már korábban
                                        elmentett üzlet ugyanezen mezőivel), akkor mentéskor az oldal
                                        hibát jelez. Ilyenkor továbblépés csak egyedi cím megadása után lehetséges.
                                    </p>
                                </span>
                            </i>
                        </div> -->
                        <app-select [inputName]="'storeAddrCountry'" [label]="'Ország'" [objArray]="countryOptions"
                            class="input-very-narrow">
                        </app-select>
                        <app-input-text [inputName]="'storeAddrZIP'" [label]="'Irányítószám'" [maxLength]="10"
                            class="input-very-narrow" (blurEvent)="emitBlurEvent($event, 'storeAddrZIP')">
                        </app-input-text>
                        <app-autocomplete [inputName]="'storeAddrCity'" [label]="'Város'" [options]="allCity"
                            class="input-narrow" (blurEvent)="emitBlurEvent($event, 'storeAddrCity')">
                        </app-autocomplete>
                        <app-input-text [inputName]="'storeAddrStreet'" [label]="'Utca'" [maxLength]="60"
                            class="input-narrow" (blurEvent)="emitBlurEvent($event, 'storeAddrStreet')">
                        </app-input-text>
                        <app-input-text [inputName]="'storeAddrNo'" [label]="'Házszám'" [maxLength]="20"
                            class="input-very-narrow" (blurEvent)="emitBlurEvent($event, 'storeAddrNo')">
                        </app-input-text>
                        <app-google-maps #googlemaps (mapManuallyDragedChanged)="mapManuallyDragedChanged($event)"
                            (mapPending)="mapPending($event)"
                            [mapManuallyRepositioned]="mapManuallyRepositioned"></app-google-maps>
                    </div>
                    <div class="popup-window_block">
                        <!-- <h3 class="section-title">Yalty város</h3> -->
                        <div class="tooltip-container">
                            <app-autocomplete [inputName]="'yaltyCity'" [label]="'Yalty város'" [options]="yaltyCities"
                                class="input-wide">
                            </app-autocomplete>
                            <i class="fa fa-info-circle tooltip">
                                <span class="yaltytiptext">
                                    <p>
                                        Opcionális mező. Akkor érdemes megadni, ha az Üzlet címénél a Város mező
                                        kiválasztott értéke nem Yalty város (hanem például egy környező kisebb
                                        település).
                                        Ekkor itt, ebben a mezőben megadható, hogy melyik Yalty városhoz tartozzon az
                                        üzlet.
                                    </p><br>
                                    <p>
                                        Virtuális (online) üzlet megadása esetén ez a mező határozza meg, hogy melyik
                                        Yalty
                                        városban jelenjen meg.
                                    </p><br>
                                    <p>
                                        Megjegyzések:
                                        - Yalty város: az applikációban (illetve ebben a mezőben) kiválasztható város.
                                        - Egy Yalty városhoz közeli településhez tartozó üzlet és a hozzátartozó
                                        promóció
                                        akkor is megjenik egy adott Yalty városhoz, ha az összerendelés ebben a mezőben
                                        nincs megadva. Ennek az oka, hogy egy Yalty város közelében lévő üzleteket és
                                        promócióikat közelségük alapján is az adott Yalty városhoz rendeljük (a közelség
                                        az
                                        adott Yalty várostól függően tipikusan 5-10-20 km környezetet jelent).
                                    </p>
                                </span>
                            </i>
                        </div>
                    </div>
                </form>
            </div>
            <div class="cancel-save-button-container">
                <button mat-flat-button class="cancel-button" (click)="closeStoreWindow()">
                    <span>MÉGSEM</span>
                </button>
                <button mat-raised-button color="primary" (click)="saveButtonClicked()"
                    [disabled]="waitingForAddOrUpdateAPIResult || !formModified" class="save-button">
                    <span [hidden]="waitingForAddOrUpdateAPIResult">MENTÉS</span>
                    <img *ngIf="waitingForAddOrUpdateAPIResult" class="loading" src="imgs/loading_indicator.gif">
                </button>
            </div>
        </div>
    </div>
</div>