// @ts-nocheck

angular.module('yaltyApp')
    .controller('loyaltyCardRegistration72Ctrl', function ($scope, $timeout, $rootScope, $http, $state, $location, API_PREFIX, AuthService, $mdDialog, GoogleAnalyticsService, $cookies, $window, $filter) {
        console.log($state.params);
        if ($state.params.lang != null) {
            $scope.lang = $state.params.lang;
        }
        if ($state.params.fname != null) {
            $scope.firstName = $state.params.fname;
        }
        if ($state.params.lname != null) {
            $scope.lastName = $state.params.lname;
        }
        if ($state.params.email != null) {
            $scope.emailAddress = $state.params.email;
        }
        if ($state.params.token != null) {
            $scope.token = $state.params.token;
        }
        $scope.registration = function () {
            $scope.registrationInProgress = true;
            if (checkFormForErrors()) {
                $scope.registrationInProgress = false;
            } else {
                let data = {
                    token: $scope.token, 
                    cardRegistrationType: 2, 
                    companyId: 512, 
                    loyaltyCardId: 72, 
                    scannedBarcodeType: 1, 
                    scannedBarcodeSubType: 21, 
                    customerLoyaltyIdPrefix: '5996272', 
                    customLoyaltyIdVariableLength: 6,
                    initPointBalance: null,
                    initCurrencyBalance: 0,
                    initLoyaltyLevelBalance: 'ezüst',
                    registrationData: {
                        lastName: $scope.lastName,
                        firstName: $scope.firstName,
                        emailAddress: $scope.emailAddress
                    }
                };
                $http.post(API_PREFIX.url + '/loyaltyCard/addOrUpdateLoyaltyCardInstance_v3', data).then(function (result) {
                    $scope.registrationInProgress = false;
                    if (result.data.errorCode == 0) {
                        const alert = $mdDialog.alert()
                            .title('Sikeres regisztráció')
                            .textContent('A kártya regisztrálása sikeresen megtörtént.')
                            .ok('Rendben')
                        $mdDialog.show(alert).then(function () {
                            location.href = "service/loyaltyCardRegistrationSuccessful?id=" + result.data.registeredLoyaltyCardInstanceId;
                        });
                    } else {
                        const alert = $mdDialog.alert()
                            .title("Sikertelen regisztráció")
                            .textContent("Szerver hiba történt, kérlek próbáld meg újra!")
                            .ok('Rendben')
                        $mdDialog.show(alert).then(function () { });
                    }
                }, function () {
                    $scope.registrationInProgress = false;
                    const alert = $mdDialog.alert()
                        .title("Sikertelen regisztráció")
                        .textContent("Szerver hiba történt, kérlek próbáld meg újra!")
                        .ok('Rendben')
                    $mdDialog.show(alert).then(function () { });
                });
            }
        }

        function checkFormForErrors() {
            var validEmailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            console.log("emaiil: "+ $scope.emailAddress);
            if ($scope.emailAddress == undefined || $scope.lastName == undefined || $scope.firstName == undefined) {
                const alert = $mdDialog.alert()
                    .title("")
                    .textContent("A mezők kitöltése kötelező.")
                    .ok('Rendben')
                $mdDialog.show(alert);
                return true;
            } else if ($scope.acceptedGeneralConditions != true) {
                const alert = $mdDialog.alert()
                    .title("")
                    .textContent("A kártya regisztrációjához kérjük fogadd el az általános szerződési feltételekben és az adatkezelési tájékoztatóban foglaltakat.")
                    .ok('Rendben')
                $mdDialog.show(alert);
                return true;
            }else if(!validEmailFormat.test($scope.emailAddress)){
                const alert = $mdDialog.alert()
                    .title("")
                    .textContent("Helytelen email formátum, kérjük ellenőrizd.")
                    .ok('Rendben')
                $mdDialog.show(alert);
                return true;
            }
        }
    })